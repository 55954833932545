@use '../../../scss/abstracts/' as *;

.refund-process {
  max-width: 666px;

  h2 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  p.desc {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 22px;
  }

  .account-data {
    display: flex;
    column-gap: 37px;
    margin-bottom: 27px;

    div:first-child {
      display: flex;
      flex-direction: column;
      row-gap: 22px;

      p {
        font-size: 12px;
        font-weight: 700;
      }
    }

    div:last-child {
      display: flex;
      flex-direction: column;
      row-gap: 22px;

      p {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  .request-btn {
    button {
      width: 145px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 6px;
    }
  }

  .action-btn {
    margin-top: 23px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 12px;
  }
}
