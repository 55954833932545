@use '../../../scss/abstracts/' as *;

.broadcast-task-modal {
  width: 610px;

  h2 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 24px;
  }

  form {
    .date-create {
      margin-top: 24px;
    }

    .curse-currency {
      margin-bottom: 24px;
    }

    .driver-list {
      margin-top: 24px;
    }

    .drivers-wrapper {
      margin-top: 24px;
      position: relative;

      .checkbox {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .task-type {
      margin-top: 24px;

      .checkboxes {
        display: flex;
        width: 100%;
        align-items: center;
        column-gap: 16px;
      }
    }

    .action-buttons {
      margin-top: 26px;
      display: flex;
      justify-content: flex-end;
      column-gap: 12px;
    }
  }
}
