@use '../../../../../../../scss/abstracts/' as *;

.table-locations {
  h3 {
    font-size: $font-small;
    margin-bottom: 20px;
    margin-top: 18px;
  }

  .exchange-rate {
    margin-top: 4px;
    font-weight: 600;
  }

  &__table-wrapper {
    border: 1.5px solid rgba(224, 224, 224, 1);
    border-radius: 4px;

    .table-content {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;

      thead {
        background: rgba(224, 224, 224, 0.3);
        border-bottom: 1.5px solid rgba(224, 224, 224, 1);

        th {
          padding: 13px 0;
          font-size: $font-medium;
          font-weight: 700;
        }

        th:first-child,
        th:nth-child(2) {
          width: 37%;
          border-right: 1.5px solid rgba(224, 224, 224, 1);
        }

        th:last-child {
          width: 26%;
        }
      }

      tbody {
        tr {
          border-bottom: 1.5px solid rgba(224, 224, 224, 1);

          td:first-child,
          td:nth-child(2) {
            border-right: 1.5px solid rgba(224, 224, 224, 1);

            .location-wrapper {
              width: 100%;
              display: grid;
              grid-template-columns: repeat(2, 1fr);

              div.grid-item {
                border-right: 1px solid rgba(224, 224, 224, 1);
              }

              &__location {
                div.select-field-shuttle__input-field {
                  outline: 0;
                  background-color: transparent;

                  input {
                    background-color: transparent;
                  }
                }

                ul.select-field-shuttle__dropdown {
                  max-height: 264px;
                  padding-right: 8px;
                  scrollbar-width: 4px;
                  scrollbar-color: #e0e0e0;

                  /* Menyelaraskan tampilan scrollbar */
                  &::-webkit-scrollbar {
                    height: 12px; /* Tinggi scrollbar horizontal */
                    width: 4px;
                  }

                  &::-webkit-scrollbar-track {
                    background: transparent; /* Warna track scrollbar */
                    margin-top: 9px;
                    margin-bottom: 9px;
                  }

                  &::-webkit-scrollbar-thumb {
                    background: #e0e0e0; /* Warna thumb scrollbar */
                    border-radius: 10px; /* Radius untuk sudut bulat */
                  }

                  &::-webkit-scrollbar-thumb:hover {
                    background: #f1f1f1; /* Warna thumb scrollbar saat dihover */
                  }

                  // for Firefox
                  &::-moz-scrollbar {
                    width: 4px;
                    height: 12px;
                  }

                  &::-moz-scrollbar-track {
                    background: transparent; /* Warna track scrollbar */
                    margin-right: 9px;
                    margin-left: 9px;
                  }

                  &::-moz-scrollbar-thumb {
                    background: #e0e0e0; /* Warna thumb scrollbar */
                    border-radius: 10px; /* Radius untuk sudut bulat */
                  }

                  &::-moz-scrollbar-thumb:hover {
                    background: #f1f1f1; /* Warna thumb scrollbar saat dihover */
                  }

                  li {
                    padding: 26px 14px;
                  }
                }
              }

              &__type-location {
                .field-wrapper {
                  .input-text-field {
                    outline: 0;
                    min-height: 46px;
                    pointer-events: none;
                    background-color: transparent;
                  }

                  .input-icon-left {
                    width: 0.9rem;
                  }
                }
              }
            }
          }

          td:last-child {
            .price-wrapper {
              position: relative;
              width: 100%;

              &__price {
                padding: 0 20px;

                .field-wrapper {
                  .input-text-field {
                    outline: 0;
                    text-align: center;
                    background-color: transparent;
                  }
                }
              }

              .read-only {
                pointer-events: none;

                .field-wrapper {
                  .input-text-field {
                    pointer-events: none;
                  }
                }
              }

              &__xsign {
                position: absolute;
                top: 50%;
                right: 14px;
                transform: translateY(-50%);
                cursor: pointer;
              }
            }
          }
        }

        tr:nth-child(odd) {
          background: white;
        }

        tr:nth-child(even) {
          background: rgba(224, 224, 224, 0.3);
        }
      }

      tfoot {
        td {
          .add-route-wrapper {
            width: 100%;
            padding: 12px 0;
            display: flex;
            align-items: center;
            justify-content: center;

            div {
              display: flex;
              align-items: center;
              justify-content: center;
              column-gap: 8px;
              cursor: pointer;

              p {
                font-size: $font-small;
                color: $lightBlue;
              }

              p:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}
