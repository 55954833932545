@use '../../../../scss/abstracts/' as *;

.garage-detail {
  margin-bottom: 122px;

  &__main-header,
  &__additional-header {
    font-size: 16px;
    margin-bottom: 20px;
  }

  &__main-setting {
    &__form {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 41px;
      row-gap: 20px;
      margin-bottom: 28px;
    }

    .setting-types {
      border-bottom: 1px dashed #bbbbbb;
      margin-bottom: 36px;

      &:last-of-type {
        border: none;
      }
    }
  }

  &__additional-setting {
    box-shadow: 0px 0px 4px 0px #00000040;
    border-radius: 12px;
    padding: 28px 20px;
    margin-bottom: 36px;

    &__form {
      display: grid;
      grid-template-columns: 50% auto;
      column-gap: 41px;
      row-gap: 20px;
    }
  }

  &__exchange-rate {
    padding: 4px 8px;
    background-color: #f5f5f5;
    width: fit-content;
    border-radius: 6px;

    p {
      font-size: 12px;
      margin-bottom: 4px;
    }

    .rate {
      font-size: 14px;
      font-weight: 600;
    }
  }

  &__divider {
    border: 1px solid #bbbbbb;
    border-style: dashed;
    height: 1px;
    width: 100%;
    position: relative;
    margin: 24px 0;
  }

  &__add-button,
  &__remove-button {
    border-radius: 100%;
    padding: 0;
    min-width: 0;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__time {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 17px;
  }

  // &__status-wrapper {
  //   width: 100%;
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   margin: 1rem 0;
  //   // max-width: calc(50% - 6px);
  //   background-color: white;
  //   border-radius: 4px;
  //   padding: 1rem;
  // }

  &__operational-status {
    p {
      font-size: 12px;
      font-weight: 700;
    }
  }

  &__status-info {
    margin-top: 16px;
    display: flex;
    align-items: center;

    p {
      font-size: $font-small;
      font-weight: 700;
    }
  }

  &__buttons {
    justify-content: flex-end;
    display: flex;
  }

  .select-field-vehicle__dropdown {
    max-height: 250px;
  }
}
